<template>
  <div class="box t-center">
    <h1>Hello！{{ userName }}</h1>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      userName: localStorage.getItem("__username__"),
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding-top: 100px;
  > img {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 50%;
  }
}
</style>
